<template>
  <div>
    <p class="AssociatedDrug_header">
      <span>
        关联用药
      </span>
    </p>
    <div class="AssociatedDrug_main">
      <p class="AssociatedDrug_main_msg">
        关联理由：
        <span>{{goodsinfo.relevanceMessage}}</span>
      </p>
      <div class="AssociatedDrug_mainBox">
        <div class="AssociatedDrug_main_left">
          <div class="AssociatedDrug_list">
            <div class="imgWrap">
              <img
                :src="pic"
                alt=""
              />
            </div>
            <h3 class="shopName" :title="goodsinfo.name+'('+goodsinfo.brandName+')'">{{goodsinfo.name}}({{goodsinfo.brandName}})</h3>
            <div class="Price"><span class="price">{{goodsinfo.price==="***"?"会员可见":goodsinfo.price  | capitalize}}</span> <span class="productnum">x{{goodsinfo.minOrderNum}}</span></div>
          </div>
          <div class="AssociatedDrug_mainAdd">
            <i class="el-icon-circle-plus" aria-hidden="true"></i>
          </div>
        </div>
<!-- 关联商品循环展示 -->
        <div class="AssociatedDrug_main_center">
          <div class="AssociatedDrug_list" v-for="(item, index) in RelevanceProducts" :key="index">
            <div class="imgWrap"  @click="jumpDetail(item)">
              <img
                :src="item.pic.split(',')[0]"
                alt=""
              />
            </div>
            <h3 class="shopName" :title="item.name+'('+item.brandName+')'">{{item.name}}({{item.brandName}})</h3>
            <div class="Price">
              <span class="price">￥{{item.price}}</span> <span class="productnum">x{{item.minOrderNum}}</span>
              <el-checkbox label=""  v-model="item.checked" @change="checkedFun(item)"></el-checkbox>
            </div>
          </div>
        </div>
        <div class="AssociatedDrug_main_right">
          <div class="AssociatedDrug_mainEqual">
            <span>=</span>
          </div>
          <div class="AssociatedDrug_mainBtn">
              <p>选中商品一起加入购物车</p>
              <p>总价：<span class="Drugtotal">￥{{totalPrice}}</span></p>
              <button  @click="addCartFun(productsId)">加入购物车</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.AssociatedDrug {
  margin-bottom: 16px;
  .AssociatedDrug_header {
    font-weight: bold;
    height: 50px;
    background: #f6f6f6;
    span {
      background: #FFD303;
      color: #000;
      font-size: 16px;
      display: inline-block;
      width: 120px;
      text-align: center;
      line-height: 50px;
    }
  }
  .AssociatedDrug_main {
    box-sizing: content-box;
    padding: 0 0px 0 50px;
    margin-bottom: 30px;
    float: left;
    width: calc(100% - 50px);
    .AssociatedDrug_main_msg{
      color: #666;
      font-size: 14px;
      line-height:40px;
      margin-top:20px;
      float: left;
      width: 100%;
    }
    .AssociatedDrug_mainBox {
      .AssociatedDrug_list {
        float: left;
        width: 110px;
        height: 160px;
        padding-right: 20px;
        .imgWrap {
          width: 110px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .shopName {
          font-size: 12px;
          color: #666;
          line-height: 25px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .Price {
          font-size: 14px;
          color: #f43c38;
          line-height: 25px;
          font-weight: bold;
          .el-checkbox {
            float: right;
          }
          .productnum{
            font-size: 12px;
            color: #999;
          }
        }
      }
      .AssociatedDrug_main_left {
        float: left;
        padding-right: 20px;
        .AssociatedDrug_mainAdd {
          float: left;
          height: 160px;
          width: 50px;
          line-height: 160px;
          text-align: center;
          i {
            color: #FFD303;
            font-size: 34px;
          }
        }
      }

      .AssociatedDrug_main_center {
        float: left;
      }
      .AssociatedDrug_main_right {
        float: right;
        .AssociatedDrug_mainEqual {
          float: left;
          height: 160px;
          width: 50px;
          line-height: 160px;
          text-align: center;
          span{
                display: block;
                width: 30px;
                height: 30px;
                font-size: 30px;
                border-radius: 50%;
                background: #FFD303;
                color: #000;
                text-align: center;
                line-height: 26px;
                margin-left: 10px;
                margin-top: 58px;
          }
        }
        .AssociatedDrug_mainBtn{
            width: 132px;
            height: 92px;
            float: left;
            padding: 34px 24px;
            p{
                line-height:25px;
                color: #666;
                font-size: 12px;
                font-weight: bold;
                span{
                    color: #FFD303;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
            button{
                background: #FFD303;
                color: #000;
                width: 110px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                font-size: 12px;
                font-weight: bold;
                border: none;
                margin-top: 8px;
            }
        }
      }
    }
  }
}
</style>
<script>
import Qs from "qs";
import {AddCart,GetRelevanceProducts} from 'api/product.js';
import { mapState,mapMutations,mapGetters} from "vuex";
//  import Qs from 'qs'
export default {
  name: "AssociatedDrug",
  data() {
    return {
      RelevanceProducts:[],
      productsId:[],
      pic:''
    };
  },
   props: {
    goodsinfo:{
      type: Object,
    },
  },
  components: {},
   created(){
      this.getRelevanceProducts();
      this.pic=this.goodsinfo.pic.split(",")[0]
    },
  computed:{
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    }),
    totalPrice(){
      if(this.goodsinfo.price=="***"){
        let totalVal="会员可见"
        return totalVal
      }else{
          let totalVal=Number(this.goodsinfo.price)*Number(this.goodsinfo.minOrderNum);
          this.RelevanceProducts.map(item=>{
            if(item.checked){
              if(item.price==="***"){
                  totalVal='***'
              }else{
                  totalVal+=Number(item.price)*Number(item.minOrderNum);
              }
            }
          })
          return totalVal.toFixed(2)
      }
    }
  },
  methods: {
      ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
     checkedFun(item){
      if(item.checked){
        let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
         this.productsId.push({
                isRecentExpiration:item.isRecentExpiration,
                quantity:item.minOrderNum,
                productId:item.id,
                goodNum:item.goodNum,
                memberStoreId:memberStoreId
              })
      }else{
         this.productsId.forEach((items,i)=>{
            if(items.productId==item.id){
                this.productsId.splice(i,1);
            }
          })
      }
    },
     jumpDetail(item) {
      this.$router.push({ name: "ProductDetail", query: { id: item.id,isRecentExpiration:item.isRecentExpiration } });
    },
     addCartFun(addcartDate) {
      if(this.goodsinfo.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });
             let count=0
            addcartDate.forEach(item=>{
              count+=item.quantity
            })
             this.setCarGoodsNum(count);
        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    },
    //  通过商品id获取关联商品信息
    getRelevanceProducts:function(){
        const parmes={id:this.goodsinfo.id};
          let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
        this.productsId.push({
          isRecentExpiration:this.goodsinfo.isRecentExpiration,
          quantity:this.goodsinfo.minOrderNum,
          productId:this.goodsinfo.id,
          goodNum:this.goodsinfo.goodNum,
           memberStoreId:memberStoreId
        })
        GetRelevanceProducts(parmes).then(res=>{
              if(res.data.code==400){
                return false
              }
              res.data.data.map(item => {
                item.checked = true
                return item
              });
              this.RelevanceProducts=res.data.data
              if(this.RelevanceProducts.length==0){
                  this.$emit('ShowFun')
              }

             
              this.RelevanceProducts.forEach(item=>{
                this.productsId.push({
                  isRecentExpiration:item.isRecentExpiration,
                  quantity:item.minOrderNum,
                  productId:item.id,
                  goodNum:item.goodNum,
                   memberStoreId:memberStoreId
                })
              })
          })
     }
  
  },
};
</script>
